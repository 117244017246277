<template>
    <ConsignmentNoteRegistryForm />
</template>

<script>
import ConsignmentNoteRegistryForm from "@/components/ConsignmentNoteRegistry/ConsignmentNoteRegistryForm";
export default {
    name: 'CreateConsignmentNotesRegistry',
    components: {ConsignmentNoteRegistryForm},
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Создание реестра товарных накладных' }
        ]
    })
}
</script>
